.layout__col{
	width: 100%;
	display: flex;
	background: none;
	align-items: center;
	flex-direction: column;

	&__ai{
		&--fe{align-items: flex-end;}
		&--cn{align-items: center;}
		&--fs{align-items: flex-start !important;}
	}

	&__jc{
		&--cn{justify-content: center;}
		&--fe{justify-content: flex-end;}
		&--fs{justify-content: flex-start;}
		&--se{justify-content: space-evenly}
		&--sa{justify-content: space-around}
		&--sb{justify-content: space-between;}
	}
}