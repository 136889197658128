@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:wght@100;300;400;700;900&family=Roboto:wght@300;400;500;700;900&family=Style+Script&display=swap');

.typo{

	font-size: 2rem;
	cursor: inherit;
	color: $color-black;
	transition: all 0.2s ease-in-out;
	font-family: 'Raleway', sans-serif;
	
	&__style{
		font-family: 'Lato', sans-serif;
	}

	&__display{
		font-size: 15rem;
		font-weight: 500;

		@include respond(phone){font-size: 4.2rem;}
		
		&--2{ 
			font-size: 5rem;
			font-weight: 500;

			@include respond(phone){font-size: 3.4rem;}
		}
		&--4{ 
			font-size: 7rem;
			@include respond(phone){font-size: 3rem;}
		}
		&--5{
			font-size:10rem;
			@include respond(phone){font-size: 6rem;}

		}
		&--3{ 
			font-size: 3.8rem;
			font-weight: 500;
		}
	}

	&__heading{
		font-size: 2.8rem; font-weight: normal;

		@include respond(phone){font-size: 2.4rem;}

		&--1{ 
			font-size: 2.4rem;

			@include respond(phone){font-size: 2rem;}
		}
		&--2{ 
			font-size: 3.0rem;

			@include respond(phone){font-size: 2rem;}
		}
		&--3{ 
				font-size: 2rem; 
				@include respond(phone){font-size: 1.6rem;}

			}
		&--4{
			font-size:3.4rem;

			@include respond(phone){font-size: 2.4rem;}
		}
		&--5{
			font-size:2.2rem;
		}
	}


	&__body{
		&--2{ 
			font-size: 1.8rem; 
			@include respond(phone){font-size: 1.4rem}
		}
		&--3{ 
			font-size: 1.4rem;
		}
		&--4{ 
			font-size: 1.8rem;

			@include respond(phone){font-size: 1.2rem;}
		}
		&--5{
			font-size: 2rem;
			@include respond(phone){font-size: 1.2rem;}
		}
		&--6{ 
			font-size: 1.4rem;

			@include respond(phone){font-size: 1rem;}
		}
	}

	&__action{
		cursor: pointer;
		font-weight: 500;
	}
	
	&__caption{
		font-size: 1.4rem;
		color: $color-gray;
		font-style: italic;
		
		&--1{
			font-size: 1.2rem;
		}
	}

	&__align{
		&--center{text-align: center;}
		&--right{text-align: right;}
	}

	&__bold{
		&--1{font-weight: bold}
	}

	&__lighter{
		&--1{font-weight: lighter}
	}

	&__decoration{

		&--none{
			text-decoration: none;
		}

		&--underline{
			text-decoration: underline;
		}
	}

	&__transform{
		&--capital{text-transform: capitalize;}
		&--upper{text-transform: uppercase;}
		&--lower{text-transform: lowercase;}
	}
	
	&__color{
		&--primary{ color: $color-primary !important;}
		&--secondary{ color: $color-secondary !important;}
		&--gray{
			color: $color-gray !important;

			&-dark{color: $color-gray-dark}
		}
		&--green{color: green !important;}
		&--white{color: $color-white !important;}
		&--accent{
			&-1{color: $color-accent-1 !important}
			&-2{color: $color-accent-2 !important}
			&-3{color: $color-accent-3 !important}
		}
	}

	&--danger{ color: $color-red !important;}
	&--success{ color: $color-green !important;}
	&--warning{ color: $color-yellow !important;}
	&--info{ color: $color-blue !important;}

	&--orange{color: $color-orange-light;}
	&--pink{ color: $color-pink !important;}
	&--white{color: $color-white !important;}
	&--black{color: $color-black !important;}
	
	&--nowrap{white-space: nowrap;}
	&--noselect{user-select: none;}
}