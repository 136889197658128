*,
*::after,
*::before{
	margin:0;
	padding:0;
	box-sizing: inherit;
}

html{ 
	font-size: 62.5%;
	scroll-behavior: smooth;
	color: $color-black;
	background-color: $color-white;
	user-select: none;
	@include respond(desBig){ font-size: 62.5% ; }
	@include respond(desk){ font-size: 50% ; }
	@include respond(tabLand){ font-size: 56.25% ; }
	@include respond(tabPort){ font-size: 50% ; }
	@include respond(phone){ font-size: 60% ; }
}

body{ box-sizing: border-box; }

main{ min-height: 100vh; }

textarea, input{
	transition: all 0.4s ease-in-out;
	border: 0.2rem solid $color-black;

	&:focus {
		outline: none !important;
		border: 0.2rem solid $color-primary;
	}
}

table{
	width: 100%;
	border-collapse: collapse;
	margin-top: 2rem;
	margin-bottom: 4rem;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
input[type=number] { -moz-appearance: textfield; }

a{
	&:not(:visited){color: $color-primary}
	&:visited{color: $color-secondary}
}