$color-primary: #6c5dd3;
$color-secondary: #0049c6;
$color-accent-1: #ffa2c0;
$color-accent-2: #ffce73;
$color-accent-3: #8cb880;
$color-black: #000;
$color-gray: #78797b;
$color-gray-light: #8D8E90;
$color-gray-dark: #222;
$color-white: #fafafa;
$color-pink:#d22a83;
$color-pink-light:#d273a6;
$color-very-light-blue:#c8d1db;
$color-white--2:#cecece;

$color-red: #ff0000;
$color-yellow: #f1bf42;
$color-orange-light:#F2A93B;

$color-orange:#F2A93B;
$color-green: #4f9252;
$color-green-teal:#25d366;
$color-green-light: #a9f6ad;
$color-blue: #3772E0;

/*	FONT SIZES	*/
$text-default-size: 1.6rem;