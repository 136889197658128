.d{

	&--f{
		display:flex;

		&--c{ flex-direction: column; }

		&--r{ flex-direction: row; }
		&--rv{ flex-direction: row-reverse; }
	}

	&__w{
		&--100{ width: 100% }
		&--85{ width: 85% }
		&--70{ width: 70%}
		&--55{ width: 55% }
		&--50{ width: 50% }
		&--30{ width: 30% }
		&--25{ width: 25% }
		&--20{ width: 20% }
		&--15{ width: 15% }
		&--fc{ width: fit-content; }
		&--30r{ min-width: 30rem;}
		&--20r{ min-width: 20rem;}

		&f{
			&--40{
				width: 40% !important;
				@include respond(phone){width: 100% !important;}
			}
			&--20{
				width: 20% !important;
				@include respond(phone){width: 100% !important;}
			}
		}
	}

	&__h{
		&--100h{ height: 100vh }
		&--90h{ height: 90vh }
		&--10h{ height: 10vh }
		&--100{ height: 100% }
		&--50{ height: 50% }
		&--fc{ height: fit-content; }
	}
}

.jc{
	&--c{ justify-content: center; }
	&--e{ justify-content: flex-end; }
	&--s{ justify-content: flex-start; }
	&--se{ justify-content: space-evenly;}
	&--sa{ justify-content: space-around; }
	&--sb{ justify-content: space-between; }
}

.ai{
	&--c{ align-items: center; }
	&--fe{ align-items: flex-end; }
	&--bs{ align-items: baseline; }
	&--s{ align-items: flex-start; }
	&--fs{ align-items: flex-start; }
}

.fd{
	&--rr{ flex-direction: row-reverse !important;}
}

.row{display: flex; flex-direction: row; width: 100%;}