.u{

	&-v{ &-hide { visibility: hidden; } }

	&-t{ &-c{text-align: center;} }

	&-fw { width: 100%; }

    &-hw { width: 50%; }

	&-h{
		&-v{ &-100{height:100vh;} }
		&-p{ &-100{height:100%;} }
	}

	&-w{ 
		&--mc{width: -moz-max-content; width: max-content;}

		&__p{
			&--100{width: 100%;}
		}
	}

	&-c{
		&-alias{ cursor: alias !important; }
		&-all-scroll { cursor: all-scroll !important; }
		&-auto { cursor: auto !important; }
		&-cell { cursor: cell !important; }
		&-context-menu { cursor: context-menu !important; }
		&-col-resize { cursor: col-resize !important; }
		&-copy { cursor: copy !important; }
		&-crosshair { cursor: crosshair !important; }
		&-default { cursor: default !important; }
		&-e-resize { cursor: e-resize !important; }
		&-ew-resize { cursor: ew-resize !important; }
		&-grab { cursor: -webkit-grab; cursor: grab !important; }
		&-grabbing { cursor: -webkit-grabbing; cursor: grabbing !important; }
		&-help { cursor: help !important; }
		&-move { cursor: move !important; }
		&-n-resize { cursor: n-resize !important; }
		&-ne-resize { cursor: ne-resize !important; }
		&-nesw-resize { cursor: nesw-resize !important; }
		&-ns-resize { cursor: ns-resize !important; }
		&-nw-resize { cursor: nw-resize !important; }
		&-nwse-resize { cursor: nwse-resize !important; }
		&-no-drop { cursor: no-drop !important; }
		&-none { cursor: none !important; }
		&-not-allowed { cursor: not-allowed !important; }
		&-pointer { cursor: pointer !important; }
		&-progress { cursor: progress !important; }
		&-row-resize { cursor: row-resize !important; }
		&-s-resize { cursor: s-resize !important; }
		&-se-resize { cursor: se-resize !important; }
		&-sw-resize { cursor: sw-resize !important; }
		&-text { cursor: text !important; }
		&-w-resize { cursor: w-resize !important; }
		&-wait { cursor: wait !important; }
		&-zoom-in { cursor: zoom-in !important; }
		&-zoom-out {cursor: zoom-out !important; }
	}

	&-m{
		&-t{
			&-n { margin-top: 0 !important; }
			&-xs { margin-top: 1rem !important; }
			&-s { margin-top: 1.5rem !important; }
			&-m { margin-top: 2rem !important; @include respond(phone){ margin-top: 4rem !important; } }
			&-m2 { margin-top: 4rem !important; @include respond(phone){ margin-top: 4rem !important; } }
			&-b { margin-top: 8rem !important;  @include respond(phone){margin-top: 0rem !important;}}
			&-l { margin-top: 14rem !important; @include respond(phone){ margin-top: 10rem !important; } }
		}

		&-b{
			&-n { margin-bottom: 0 !important; }
			&-xs { margin-bottom: 1rem !important; }
			&-s { margin-bottom: 1.5rem !important; }
			&-m { margin-bottom: 2rem !important; }
			&-m2 { margin-bottom: 4rem !important; }
			&-b { margin-bottom: 8rem !important; @include respond(phone){margin-bottom: 4rem !important;}}
			&-l { margin-bottom: 14rem !important; }
		}

		&-v{
			&-n { margin-top: 0 !important; margin-bottom: 0 !important; }
			&-xs { margin-top: 1rem !important; margin-bottom: 1rem !important; }
			&-s { margin-top: 1.5rem; margin-bottom: 1.5rem !important; }
			&-m { margin-top: 3rem; margin-bottom: 2rem !important; }
			&-m2 { margin-top: 5rem; margin-bottom: 2rem !important; }
			&-b { margin-top: 8rem; margin-bottom: 8rem !important; }
			&-l { margin-top: 14rem; margin-bottom: 14rem !important; }
		}

		&-r{
			&-n { margin-right: 0 !important; }
			&-xs { margin-right: 1rem !important; }
			&-s { margin-right: 1.5rem !important; }
			&-m { margin-right: 2rem !important; }
			&-b { margin-right: 8rem !important; }
			&-l { margin-right: 14rem !important; }
		}

		&-l{
			&-n { margin-left: 0 !important; }
			&-xs { margin-left: 1rem !important; }
			&-s { margin-left: 1.5rem !important; }
			&-m { margin-left: 2rem !important; }
			&-b { margin-left: 8rem !important; }
			&-l { margin-left: 14rem !important; }
			&-p {margin-left: 7.5% !important;}
		}

		&-h{
			&-n { margin-right: 0 !important; margin-left: 0 !important; }
			&-xs { margin-right: 1rem !important; margin-left: 1rem !important; }
			&-s { margin-right: 1.5rem !important; margin-left: 1.5rem !important; }
			&-m { margin-right: 3rem !important; margin-left: 3rem !important; }
			&-b { margin-right: 8rem !important; margin-left: 8rem !important; }
			&-l { margin-right: 14rem !important; margin-left: 14rem !important; }
		}
	}

	&-p{
		&-t{
			&-n { padding-top: 0 !important; }
			&-s { padding-top: 1.5rem !important; }
			&-m { padding-top: 2rem !important; }
			&-b { padding-top: 8rem !important; }
			&-l { padding-top: 14rem !important; }
			&-xl { padding-top: 18rem !important; @include respond(phone){padding-top: 24rem !important;}}
		}

		&-b{
			&-n { padding-bottom: 0 !important; }
			&-s { padding-bottom: 1.5rem !important; }
			&-m { padding-bottom: 2rem !important; }
			&-m2 { padding-bottom: 4rem !important; }
			&-b { padding-bottom: 8rem !important; }
			&-l { padding-bottom: 14rem !important; }
		}

		&-v{
			&-n { padding-top: 0 !important; padding-bottom: 0 !important; }
			&-xs { padding-top: 1rem !important; padding-bottom: 1rem !important; }
			&-s { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
			&-m { padding-top: 2rem !important; padding-bottom: 2rem !important; }
			&-m2 { padding-top: 4rem !important; padding-bottom: 4rem !important; }
			&-b { padding-top: 8rem !important; padding-bottom: 8rem !important; }
			&-l { padding-top: 14rem !important; padding-bottom: 14rem !important; }
		}

		&-r{
			&-n { padding-right: 0 !important; }
			&-s { padding-right: 1.5rem !important; }
			&-m { padding-right: 2rem !important; }
			&-b { padding-right: 8rem !important; }
			&-l { padding-right: 14rem !important; }
		}

		&-l{
			&-n { padding-left: 0 !important; }
			&-s { padding-left: 1.5rem !important; }
			&-m { padding-left: 2rem !important; }
			&-b { padding-left: 8rem !important; }
			&-l { padding-left: 14rem !important; }
		}

		&-h{
			&-n { padding-right: 0 !important; padding-left: 0 !important; }
			&-xxs { padding-right: 1rem !important; padding-left: 1rem !important; }
			&-s { padding-right: 1.5rem !important; padding-left: 1.5rem !important; }
			&-m { padding-right: 2rem !important; padding-left: 2rem !important; }
			&-b { padding-right: 8rem !important; padding-left: 8rem !important; }
			&-l { 
					padding-right: 14rem !important;
					padding-left: 14rem !important; 
					@include respond(phone){
						padding-right: 3rem !important;
						padding-left: 3rem !important;
					}
				}
			&-x { padding-right: 20rem !important; padding-left: 20rem !important; }
		}
	}
}

.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
