.home{
    width: 100%;
    height: 100%;

    &__whatsappcontainer{
        z-index: 22;
        position: fixed;
        overflow: hidden;
        left: 3rem;
        bottom: 3rem;

        @include respond(phone){
            bottom: 1.8rem;
            left: calc(100% - 7.8rem);
        }

        &>a{
            &>img{
                width: 6.8rem;

                @include respond(phone){
                    width: 5.8rem;
                }
            }
        }
    }

    &__messagecontainer{
        z-index: 22;
        right: 3rem;
        bottom: 3rem;
        display: flex;
        position: fixed;
        align-items: left;
        padding: 3rem 0rem;
        padding-right: 4rem;
        border-radius: 2rem;
        flex-direction: column;
        justify-content: center;
        color: #000 !important;
        background-color: #fff;

        @include respond(phone){
            display: none;
            // right: 1rem;
            // bottom: 1rem;
            // padding: 1rem;
        }

        &__cross{
            width: 10%;
            top: 1.4rem;
            border: none;
            right: 1.6rem;
            outline: none;
            cursor: pointer;
            font-size: 2.2rem;
            text-align: right;
            position: absolute;
            background: transparent;
        }

        @include respond(phone){
            
        }

        &>h1{
            
            display: flex;
            font-size: 4rem;
            align-items: center;
            margin-bottom: 1rem;
            justify-content: start;
            padding-left: 4rem;
            
            &>img{
                width: 20%;
            }

            @include respond(phone){
                padding-left: 1rem;
                font-size: 2.4rem;
            }
        }

        &>p{
            font-size: 2rem;
            font-weight: bold;
            padding-left: 4rem;

            @include respond(phone){
                padding-left: 1rem;
            }
        }

        &__query{
            width: 60%;
            cursor: pointer;
            margin-left: 4rem;
            font-weight: bold;
            margin-top: 1.6rem;
            padding: 1rem 0rem;
            border-radius: 10rem;
            background: transparent;
            border: 0.15rem solid black;

            @include respond(phone){
                width: 70%;
                margin-left: 1rem;
                margin-bottom: 1rem;
            }

            &:hover{
                color: green;
            }
        }
    }

    &__popupcontainer{
        z-index: 100;
        width: 100vw;
        height: 100vh;
        position: fixed;
        overflow: hidden;
        background: linear-gradient(to right, rgba(0,0,0, 0.90), rgba(0,0,0, 0.90));

        &>div{
            left: 50%;
            top: 50%;
            color: #000;
            padding: 4rem;
            display: flex;
            position: fixed;
            border-radius: 2rem;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background-color: rgb(202, 255, 202);
            transform: translateX(-50%) translateY(-50%);

            @include respond(phone){
                width: 80%;
                padding: 2.2rem;
            }

            &>h1{
                font-size: 3rem;

                @include respond(phone){
                    margin-top: 1rem;
                    font-size: 2.4rem;
                }
            }

            &>img{
                position: absolute;
                transform:  translateX(85%) translateY(40%);

                @include respond(phone){
                    transform: scaleY(-1) scaleX(-1) translateX(55%) translateY(-45%) rotate(-65deg);
                }
            }

            &>p{
                border: 0;
                width: 50%;
                color: #fff;
                font-size: 2rem;
                margin-top: 3.4rem;
                text-align: center;
                border-radius: 5rem;
                padding: 1.6rem 0rem;
                background-color: green;

                @include respond(phone){
                    margin-top: 2rem;
                    padding: 1.2rem;
                }
            }

            &>button{
                top: 0;
                border: 0;
                right: 1rem;
                color: #fff;
                display: flex;
                cursor: pointer;
                font-weight: bold;
                border-radius: 50%;
                position: absolute;
                align-items: center;
                padding: 1rem 1.4rem;
                background-color: red;
                justify-content: center;
                transform: translateY(-50%);
            }
        }
    }

    &__modelcontainer{
        z-index: 100;
        width: 100vw;
        height: 100vh;
        display: flex;
        position: fixed;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        background: linear-gradient(to right, rgba(0,0,0, 0.90), rgba(0,0,0, 0.90));

        &>div{
            width: 30%;
            display: flex;
            padding: 3rem 2rem;
            position: relative;
            border-radius: 2rem;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background-color: #fff;

            @include respond(phone){
                width: 85%;
            }

            &>h1{
                color: green;
                font-size: 4rem;
                margin-bottom: 2rem;

                @include respond(phone){
                    font-size: 2.6rem;
                    margin-bottom: 1rem;
                }
            }

            &>button{
                top: 0;
                border: 0;
                right: 0rem;
                color: #fff;
                display: flex;
                cursor: pointer;
                font-size: 2.4rem;
                border-radius: 50%;
                position: absolute;
                align-items: center;
                padding: 1.2rem 1.4rem;
                justify-content: center;
                background-color: red;
                transform: translateY(-40%) translateX(40%);
            }

            &>form{
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                &>input{
                    width: 90%;
                    outline: none;
                    font-weight: bold;
                    border-radius: 1rem;
                    margin: 1.4rem 0rem;
                    padding: 1.2rem 1.6rem;
                    border: 0.2rem solid lightgray;

                    @include respond(phone){
                        padding: 1rem 1.2rem;
                    }
                }

                &>button{
                    width: 90%;
                    color: #fff;
                    outline: none;
                    cursor: pointer;
                    font-size: 1.8rem;
                    font-weight: bold;
                    margin-top: 1.4rem;
                    border-radius: 1rem;
                    letter-spacing: 0.2rem;
                    padding: 1.2rem 1.6rem;
                    background-color: green;
                    border: 0.2rem solid green;
                
                    @include respond(phone){
                        padding: 0.8rem 1.2rem;
                    }
                }

                &>p{
                    font-size: 2rem;
                    text-align: center;
                    margin-top: 1.4rem;
                    font-weight: bold;
                    color: red;
                }
            }
        }
    }

    &__videocontainer{
        height: 100vh;
        overflow-y: hidden;
        
        &__video{
            width: 100%;

            @include respond(phone){
                width: auto;
                height: 100vh !important;
            }
        }

        &__content{
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            color: white;
            position: absolute;
            flex-direction: column;
            align-items: center;
            background: linear-gradient(to right, rgba(0,0,0,0.6), rgba(0,0,0,0.6));

            &>navbar{
                height: 15%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                
                &>img{
                    margin: 0 5rem;
                    width: 5%;

                    @include respond(phone){
                        width: 25%;
                        margin-top: 4rem;
                    }
                }

                &>ul{
                    display: flex;
                    list-style: none;

                    @include respond(phone){display: none;}

                    &>li{
                        letter-spacing: 0.8rem;

                        &>a{
                            color: #fff;
                            text-decoration: none;
                        }
                    }
                }
            }

            &>div{
                width: 85%;
                height: 75%;
                display: flex;
                align-items: start;
                justify-content: end;
                flex-direction: column;

                &>h1{
                    width: 30%;
                    font-size: 10rem;

                    @include respond(phone){
                        width: 80%;
                        font-size: 5rem;
                    }
                }

                &>p{
                    width: 50%;
                    font-size: 4rem;
                    
                    @include respond(phone){
                        width: 90%;
                        font-size: 2.4rem;
                    }
                }
            }
        }
    }

    &__aboutcontainer{
        height: 75vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include respond(phone){
            height: 100%;
            flex-direction: column;
        }
        
        &--reverse{
            @include respond(phone){
                flex-direction: column-reverse;
            }
        }


        &--1{
            background:  linear-gradient(to right, rgba(0,0,0,0.1), rgba(0,0,0,0.1));
        }

        &__content{
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            letter-spacing: 0.2rem;

            @include respond(phone){
                width: 100%;
                margin-bottom: 2rem;
            }
            
            &--1{
                background: url('../../assets/images/leaf.png');
                background-position: center;
                background-size: 50%;
                background-repeat: no-repeat;
            }

            &>a{
                width: 30%;
                display: flex;
                font-weight: bold;
                align-items: center;
                padding: 1.4rem 0rem;
                text-decoration: none;
                justify-content: center;
                color: #fff !important;
                background-color: black;

                @include respond(phone){
                    width: 40%;
                    font-size: 1.4rem;
                    text-align: center;
                }
            }

        }

        &__imgcontainer{
            height: 100%;
            width: 50%;

            @include respond(phone){
                width: 100%;
                margin-bottom: 2rem;
            }

            &>img{
                width: 100%;
                height: 100%;
            }
        }
    }

    &__reviewsection{
        display: flex;
        padding: 4rem 0rem;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background:  linear-gradient(to right, rgba(0,0,0, 0.08), rgba(0,0,0, 0.08)), url('../../assets/images/back1.jpg');
        background-position: left;

        @include respond(phone){
            padding-top: 0rem;
            padding-bottom: 4rem;
        }

        &>h1{
            width: 85%;
            text-align: left;

            @include respond(phone){
                font-size: 4rem;
                text-align: center;
            }
        }

        &__review{
            width: 90%;
            display: grid;
            grid-gap: 6rem;
            grid-template-columns: repeat(4, 1fr);

            @include respond(phone){
                width: 80%;
                display: flex;
                grid-gap: 2rem;
                margin-top: 2.4rem;
                align-items: center;
                flex-direction: column;
                justify-content: center;
            }

            &>div{
                width: 100%;
                margin: 4rem;
                text-align: left;
                padding: 6rem 4rem;
                color: $color-white;
                border-radius: 3rem;

                @include respond(phone){
                    width: 90%;
                    margin: 0rem;
                    margin-top: 1.5rem;
                    margin-bottom: 1.5rem;
                    padding: 4rem;
                }

                &>h4{
                    font-size: 2.6rem;
                    letter-spacing: 0.1rem;
                    color: rgb(68, 217, 68);

                    @include respond(phone){
                        font-size: 2rem;
                    }
                }

                &>p{
                    font-size: 1.6rem;
                    padding: 3rem 0rem;
                    line-height: 1.8rem;
                    letter-spacing: 0.1rem;

                    @include respond(phone){
                        font-size: 1.4rem;
                        padding: 1.4rem 0rem;
                    }
                }

                &>div{
                    display: flex;
                    align-items: center;

                    &>img{
                        width: 5rem;
                        border-radius: 100%;
                        margin-right: 1.2rem;
                    }

                    &>div{
                        &>h6{
                            font-size: 1.6rem;
                            letter-spacing: 0.15rem;
                        }
                    }
                }
            }

            &--1{
                background: linear-gradient(to right, rgba(0, 0, 0, 0.5) ,rgba(0, 0, 0, 0.5)), url('../../assets/images/yannic-laderach-Dqx4XWuXu7w-unsplash.jpg');
                background-repeat:no-repeat ;
                background-position: top;
                background-size: cover;
            }
        }
    }

    &__tourcontainer{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        &>h1{
            width: 85%;

            @include respond(phone){
                width: 100%;
                font-size: 4rem;
                text-align: center;
                line-height: 3.4rem;
            }
        }

        &__tours{
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            @include respond(phone){
                grid-template-columns: repeat(2, 1fr);
            }
            
            &__tour{
                position: relative;

                &:hover{
                    &>div{
                        opacity: 1;
                    }
                }

                &>img{
                    width: 100%;
                    height: 100%;
                    
                }

                &>div{
                    bottom: 0;
                    opacity: 0;
                    width: 100%;
                    color: #fff;
                    display: flex;
                    padding: 1rem 2rem;
                    position: absolute;
                    background: linear-gradient(to right, rgba(0, 0, 0, 0.8) ,rgba(0, 0, 0, 0.8));

                    @include respond(phone){
                        padding: 1rem;
                    }
                    
                }

                &__content{
                    width: 70%;
                    letter-spacing: 0.1rem;

                    &>h1{
                        color: rgb(68, 217, 68);

                        @include respond(phone){
                            font-size: 1.4rem;
                        }
                    }
                }

                &__icon{
                    width: 30%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &>span{
                        @include respond(phone){
                            font-size: 1rem;
                        }
                    }

                    &>a{
                        color: #fff !important;
                    }
                }
            }
        }
    }

    &__discountcontainer{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        flex-direction: column;

        &>h1{
            width: 85%;

            @include respond(phone){
                width: 80%;
                font-size: 4rem;
                text-align: center;
                margin-top: 3rem;
            }
        }

        &>img{
            width: 60%;

            @include respond(phone){
                width: 100%;
                margin-bottom: 2rem;
            }
        }
    }

    &__instacontainer{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        
        &>h1{
            width: 85%;

            @include respond(phone){
                width: 80%;
                font-size: 4rem;
                text-align: center;
                margin-top: 2rem;
            }
        }

        &>a{
            margin-left: 7.5%;
            font-weight: bold;
            text-decoration: none;
            padding: 1.6rem 2.6rem;
            align-self: self-start;
            color: green !important;
            border: 0.2rem solid green;
            transition: all 0.4s ease-in-out;

            @include respond(phone){
                margin-left: 12.5%;
            }

            &:hover{
                color: #fff !important;
                background-color: green;
            }

        }

        &__collage{
            width: 85%;
            display: grid;
            grid-gap: 6rem;
            text-align: center;
            grid-template-columns: repeat(4, 1fr);

            @include respond(phone){
                width: 75%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
            }

            &__video{
                cursor: pointer;
                overflow-y: hidden;
                position: relative;

                &:hover{

                    &>p{
                        opacity: 0;
                        transform: translateY(100%);
                    }
                }

                &>span{
                    top :1rem;
                    right: 1rem;
                    color: #fff;
                    position: absolute;
                }

                &>p{
                    bottom: 0;
                    margin: 0;
                    opacity: 1;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    display: flex;
                    text-align: center;
                    position: absolute;
                    align-items: center;
                    padding: 1.4rem 0rem;
                    flex-direction: column;
                    justify-content: center;
                    transform: translateY(0%);
                    transition: all 0.4s ease-in-out;
                    background-color: rgba(0,0,0, 0.8);
                
                    @include respond(phone){
                        letter-spacing: 0.1rem;
                    }

                }

                &>video{
                    width: 100%;
                }

                &__watch{
                    opacity: 0;
                    color: #fff;
                    padding: 0.8rem 1.2rem;
                    border: 0.2rem solid green;

                    @include respond(phone){
                        opacity: 1;
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }

    &__footercontainer{
        display: flex;
        color: #fff;
        margin-top: 20rem;
        position: relative;
        align-items: center;
        justify-content: center;
        background-color: #151515;

        @include respond(phone){
            margin-top: 24rem;
            flex-direction: column-reverse;
        }
        
        &__formcontainer{
            top: 0%;
            left: 50%;
            color: #000;
            padding: 4rem;
            display: flex;
            position: absolute;
            border-radius: 2rem;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            transform: translate(-50%, -50%);
            box-shadow: 0px 0px 4rem rgba(0,0,0, 0.4);


            @include respond(phone){
                width: 80%;
                padding: 2rem;
                flex-direction: column;
            }

            &__content{
                width: 40%;
                padding-right: 4rem;

                @include respond(phone){
                    width: 100%;
                    text-align: center;
                    padding-right: 0rem;
                    margin-bottom: 2rem;
                }

                &>h1{
                    color: green;
                }

                &>p{
                    width: 90%;
                    line-height: 2rem;
                    font-weight: bold;

                    @include respond(phone){
                        width: 100%;
                    }
                }
            }

            &__form{
                width: 60%;
                display: grid;
                grid-gap: 2rem;
                grid-template-columns: repeat(2, 1fr);

                @include respond(phone){
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }

                &>input{
                    outline: none;
                    padding: 1rem 2rem;
                    border-radius: 0.4rem;
                    border: 0.2px solid gray;

                    &:focus, :active{
                        border: none;
                    }

                    @include respond(phone){
                        width: 80%;
                    }
                }

                &>button{
                    color: #fff;
                    cursor: pointer;
                    padding: 1rem 2rem;
                    border-radius: 0.4rem;
                    background-color: green;
                    border: 0.2px solid green;

                    &:hover{
                        color: green;
                        background-color: #fff;
                    }
                }

                &>p{
                    font-size: 2rem;
                }
            }
        }
        
        &__content{
            width: 65%;
            display: flex;
            justify-content: center;

            @include respond(phone){
                width: 100%;
                text-align: center;
                flex-direction: column;
            }


            &__address{
                width: 40%;

                @include respond(phone){
                    width: 100%;
                }

                &>h1, p{
                    &>span{
                        color: green;
                        font-weight: bold;
                    }

                    &>a{
                        color: #fff;
                        font-size: 3rem;
                    }
                }
            }

            &__links{
                width: 40%;
                text-align: left;

                @include respond(phone){
                    display: none;
                }

                &>ul{
                    list-style: none;

                    &>li{
                        display: flex;
                        align-items: center;

                        &>span{
                            color: green;
                        }
                        &>a{
                            color: #fff;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        &__image{
            width: 20%;

            @include respond(phone){
                width: 100%;
                text-align: center;
                margin-bottom: 4rem;
            }

            &>img{
                width: 60%;

                @include respond(phone){
                    width: 30%;
                }
            }
        }
    }

    &__copyright{
        color: #fff;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: #000;

        &>p{
            font-weight: bold;

            &>a{
                color: green;
            }

            &>span{
                color: green;
            }
        }
    }

} 